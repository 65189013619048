"use strict";

import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";

require("@/assets/styles/index.scss");

Vue.config.productionTip = false;

new Vue({
    router,
    "render": h => h(App),
    "data": {
        "pagetitle": "",
    },
}).$mount("#app");
