"use strict";

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let usedTime = "";
let expirationTime = "";
const getExpirationTime = () => {
    if (localStorage.getItem("expired")) {
        usedTime = new Date();
        const date = localStorage.getItem("expired");
        //const date = "2021-02-17 09:55:00";
        expirationTime = date.split(/[- :]/);
        expirationTime = new Date(expirationTime[0], expirationTime[1]-1, expirationTime[2], expirationTime[3], expirationTime[4], expirationTime[5]);
    }
};

const returnRoot = (to, from, next) => {
    getExpirationTime();
    if (localStorage.getItem("token") === null || expirationTime < usedTime) {
        return next({ "name": "offices_login" });
    }
    return next({ "name": "search" });
    // return next();
};

const returnLogout = (to, from, next) => {
    getExpirationTime();
    if (localStorage.getItem("token") === null || expirationTime < usedTime) {
        return next({ "name": "offices_logout" });
    } else if (localStorage.getItem("role") === "1") {
        return next({ "name": "search" });
    }
    return next();
};

const router = new Router({
    "mode": "history",
    "base": process.env.BASE_URL,
    "routes": [
        {
            "path": "/",
            "name": "home",
            "redirect": { "name": "search" },
        },
        {
            "path": "/search",
            "component": () => import("./layouts/Dashboard.vue"),
            "children": [
                {
                    "path": "",
                    "name": "search",
                    "component": () => import("./views/StockDataSearch.vue"),
                    "beforeEnter": returnLogout,
                },
            ],
        },
        {
            "path": "/detail",
            "component": () => import("./layouts/Dashboard.vue"),
            "children": [
                {
                    "path": ":janCode/:stockPlaceCode",
                    "name": "detail",
                    "component": () => import("./views/StockDataDetail.vue"),
                    "beforeEnter": returnLogout,
                    "props": true,
                },
            ],
        },
        {
            "path": "/offices/logout",
            "name": "offices_logout",
            "component": () => import("./layouts/OfficesLogOut.vue"),
            "meta": {
                "title": "Offices Log Out",
            },
        },
        {
            "path": "/offices/login",
            "name": "offices_login",
            "component": () => import("./layouts/OfficesLogIn.vue"),
            "meta": {
                "title": "Offices Log In",
            },
        },
        {
            "path": "*",
            "beforeEnter": returnRoot,
        },
    ],
    scrollBehavior() {
        return {
            "x": 0,
            "y": 0,
        };
    },
});

export default router;
